<template>
	<div card class="p-5 bg-white">
		<div card-body>
			<div card-title>
				<h3 class="mb-10 font-weight-bold text-dark">Account Details</h3>
			</div>
			<div card-body>
				<label for="name">Name:</label>
				<b-form-input id="name" v-model="form.name"></b-form-input>

				<label for="username" class="mt-5">Username: </label>
				<b-form-input id="username" type="text" v-model="form.username" :state="!usernameError" disabled="true"></b-form-input>
				<b-form-invalid-feedback id="username">{{ usernameError }}</b-form-invalid-feedback>

				<label for="email" class="mt-5">Email: </label>
				<b-form-input id="email" type="email" v-model="form.email" disabled="true"></b-form-input>

				<div class="row mt-5">
					<div class="col-xl-6">
						<label for="role">Role:</label>
						<b-form-select :options="isNocAdmin ? roles : roles.slice(0, 3)" v-model="form.role" />
					</div>
					<div class="col-xl-6" v-if="isNocAdmin && (form.role == 'user' || form.role == 'useradmin')">
						<label for="parent">Account Parent</label>
						<b-form-select id="parent" :options="parents" v-model="form.parent" />
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-xl-6" v-if="isNocAdmin && (form.role == 'user' || form.role == 'useradmin')">
						<label for="obalink">OBA Link</label>
						<b-form-select id="obalink" :options="obacustomers" v-model="form.OBA_CustomerID" />
					</div>
				</div>
				<div class="row mt-5" v-if="form.role == 'user' || form.role == 'useradmin' || form.role == 'admin'">
					<div class="form-group" style="display: flex; margin-left: 10px">
						<b-form-checkbox class="checkboxes" v-model="form.showtickets">
							Show Tickets
						</b-form-checkbox>
						<b-form-checkbox class="checkboxes" v-model="form.showapi"> Show API </b-form-checkbox>
						<b-form-checkbox class="checkboxes" v-model="form.showsrf"> Show SRF </b-form-checkbox>
					</div>
					<div class="form-group" style="display: flex; margin-left: 10px">
						<b-form-checkbox class="checkboxes" v-model="form.showrealtime">
							View Realtime Stats
						</b-form-checkbox>
						<b-form-checkbox class="checkboxes" v-model="form.showoptionfile">
							Show Options File
						</b-form-checkbox>
						<b-form-checkbox class="checkboxes" v-model="form.allowsnmp">
							Allow SNMP
						</b-form-checkbox>
					</div>
				</div>
				<div class="row mt-5" v-if="isNocAdmin && (form.role == 'admin' || form.role == 'noc')">
					<div class="col-xl-6">
						<div class="form-group" style="display: flex; margin-left: 10px">
							<b-form-checkbox class="checkboxes" v-model="form.engineer">
								Engineer
							</b-form-checkbox>
						</div>
					</div>
				</div>
				<div class="row" v-if="isNocAdmin && form.showsrf" style="display: flex; flex-direction: column">
					<div class="col-xl-12">
						<label>Account Manager Emails</label>
						<b-form-input
							type="text"
							v-model="form.acctmgremail"
							description="Separate emails with semicolon (ex: email1@itcglobal.com; email2@itcglobal.com)"
						/>
						<b-form-checkbox v-model="form.pendingsrf"> Make new SRFs 'Pending' </b-form-checkbox>
					</div>
				</div>

				<div class="row mt-10" v-if="form.role == 'useradmin' && form.children && form.children.length > 0">
					<div class="col-xl-12">
						<h2>Subaccounts</h2>
						<b-table :fields="['username', 'name', 'email']" :items="form.children">
							<template #cell(username)="data">
								<b-link :href="'/admineditaccount/' + data.item.AccountID">
									<span class="svg-icon svg-icon-sm svg-icon-primary" v-b-tooltip="'Edit account'">
										<inline-svg src="/media/svg/icons/Design/Edit.svg" /> </span></b-link
								><span class="px-4">{{ data.item.username }}</span>
								<span class="svg-icon svg-icon-sm svg-icon-danger" v-b-tooltip="'Remove child account from parent'">
									<inline-svg src="/media/svg/icons/Navigation/Close.svg" @click="removeChildAccount(data.item.AccountID)" />
								</span>
							</template>
						</b-table>
					</div>
				</div>
				<div style="display: flex; flex-direction: column; width: 100%">
					<div v-if="saved" class="bg-success success-msg-right">Successfully Saved!</div>
					<div style="display: flex; justify-content: flex-end">
						<button type="button" class="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4 mr-5" @click="cancelChanges()">
							Cancel
						</button>
						<button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" @click="save()">
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
	name: 'AccountInfo',
	props: ['data'],

	data() {
		return {
			roles: [
				{ value: 'user', text: 'User' },
				{ value: 'useradmin', text: 'User Admin' },
				{ value: 'video', text: 'Video Only' },
				{ value: 'staff', text: 'Staff' },
				{ value: 'noc', text: 'NOC' },
				{ value: 'admin', text: 'Admin' },
			],
			parents: [],
			form: {},
			saved: false,
			obacustomers: [],
			emailError: false,
			usernameDupe: false,
		};
	},
	mounted() {
		this.form = JSON.parse(JSON.stringify(this.data));

		// useradmin list dropdown
		this.$http.get('useradminaccountslist').then(res => {
			this.parents = res.data.data.map(p => {
				return { value: { username: p.username, AccountID: p.AccountID }, text: p.username };
			});
			this.parents.unshift({ value: { username: null, AccountID: null }, text: 'None' });
		});

		//oba list dropdown
		this.$http.get('obacustomerlist').then(res => {
			this.obacustomers = res.data.data
				.sort((a, b) => (a.CustomerName > b.CustomerName ? 1 : -1))
				.map(o => {
					return { value: o.CustomerID, text: o.CustomerName + '(' + o.CustomerCode + ')' };
				});
		});
	},
	computed: {
		...mapGetters(['currentUser', 'adminAccount']),
		isStaffNocAdmin() {
			return this.currentUser.role == 'admin' || this.currentUser.role == 'noc' || this.currentUser.role == 'staff';
		},
		usernameError() {
			if (!this.form.username) {
				return 'Min length 3';
			} else if (this.form.username.length < 3) {
				return 'Min length 3';
			} else if (this.usernameDupe) {
				return 'Username is taken';
			}
			return false;
		},
		isNocAdmin() {
			return this.currentUser.role == 'admin' || this.currentUser.role == 'noc';
		},
	},
	methods: {
		cancelChanges() {
			this.$emit('save');
		},
		save() {
			this.$http.put(`account/${this.form.AccountID}`, { data: this.form }).then(res => {
				if (res.data.data.msg && res.data.data.msg.errorInfo) {
					if (RegExp(/Duplicate\sentry.*username/).test(res.data.data.msg.errorInfo[2])) {
						this.usernameDupe = true;
					}
				} else {
					this.saved = true; // this doesn't work cause component refreshes and gets set to false again

					this.$emit('save');
				}
			});
		},

		removeChildAccount(id) {
			this.$http.post('resetaccountparent', { AccountID: id }).then(() => {
				this.$emit('save');
			});
		},
	},
};
</script>

<style>
.checkboxes {
	margin-right: 5px;
	margin-left: 5px;
}
</style>
